// styles

import './App.css';

// components

import Home from './Home/Home';
import Footer from './Footer/Footer';
import Nav from './Nav/Nav';
import ScrollToTop from './Components/ScrollToTop';
import Posts from './Posts/Posts';
import PageNotFound from './Components/PageNotFound';
import Modal from './Components/Modal';

// npm packages

import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';


function App() {
  
  let [darkMode, darkModeOn] = useState(false);
  let [modal, modalToggle] = useState(false);

  function logLayout() {
    if(darkMode === false) {
        darkModeOn(darkMode = true)
    } else {
        darkModeOn(darkMode = false)
    }
  }

  function toggleModal() {
    if(modal === false) {
      modalToggle(modal = true)
    } else {
      modalToggle(modal = false)
    }
  }

  

  switch (darkMode) {
    case false:
      document.body.style.background = "var(--light-background)"
      return (
      <div className="home default">
        <Modal modal={modal} toggleModal={toggleModal} />
        <ScrollToTop />
        <Nav logLayout={logLayout} toggleModal={toggleModal} modal={modal} darkMode={darkMode}/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/posts/:id' element={<Posts />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        <Footer />
      </div>
    )
    case true:
      document.body.style.background = "#000000"
      return (
        <div className="home dark">
          <Modal modal={modal} toggleModal={toggleModal} />
          <ScrollToTop />
          <Nav logLayout={logLayout} toggleModal={toggleModal} modal={modal} darkMode={darkMode}/>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/posts/:id' element={<Posts />} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
          <Footer />
        </div>
      )
    default:
      document.body.style.background = "var(--light-background)"
      return (
      <div className="home default">
        <Modal modal={modal} toggleModal={toggleModal} />
        <ScrollToTop />
        <Nav logLayout={logLayout} toggleModal={toggleModal} modal={modal} darkMode={darkMode}/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/posts/:id' element={<Posts />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        <Footer />
    </div>
    )
  }
}

export default App;
