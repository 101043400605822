import './Modal.css'
import avatarFull from './assets/ava.png'
import { motion } from 'framer-motion'

export default function Modal( { modal, toggleModal } ) {
    if (modal === false ) {
        return(null)
    } else {
        return (
            <div className="modalBackground" onClick={toggleModal}>
                <motion.div animate={{y: 0, opacity: 1}} initial={{y: 30, opacity: 0}} className="modalContent">
                    <div className="cancel"><p className="cancelText">+</p></div>
                    <div className="modalImageContainer">
                        <img src={avatarFull} className="modalImage" alt="" />
                    </div>
                    <div className="modalText">
                        <p>Mijn naam is Koen ik ben een front-end webontwikkelaar. Ik heb passie voor het creëren van prachtige en gebruiksvriendelijke websites, en ik ben blij dat ik een aantal jaar geleden van deze passie mijn carrière heb kunnen maken.</p>
                    </div>
                </motion.div>
            </div>
        )
    }
}