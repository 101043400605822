import './Forecast.css'
import { useState } from 'react'
import { motion } from 'framer-motion'

export default function Forecast({weatherResponse}) {


    if (weatherResponse.error != null) {
        return(
            <motion.p className='forecastError' animate={{opacity: 1, x: 0}} initial={{opacity: 0, x: 20}}>Probeer een andere locatie of kijk na of de spelling correct is.</motion.p>
        )
    } else if (weatherResponse === '') {
        return(null)
    } else {
        return(
            <motion.div animate={{opacity: 1, x: 0}} initial={{opacity: 0, x: 20}} className="forecast">
                <p className="forecastMessage">Je zoekt naar de weerverwachting in <strong>{weatherResponse.location.name}</strong></p>
                <div className="forecastDays">
                    <div className="forecastDayOne forecastItem">
                        <p className="forcastTempHigh">{weatherResponse.forecast.forecastday[0].day.maxtemp_c} &deg;C</p>
                        <p className="forcastTempLow">{weatherResponse.forecast.forecastday[0].day.mintemp_c} &deg;C</p>
                        <p className="forcastDate">{weatherResponse.forecast.forecastday[0].date}</p>
                    </div>
                    <div className="forecastDayOne forecastItem">
                        <p className="forcastTempHigh">{weatherResponse.forecast.forecastday[1].day.maxtemp_c} &deg;C</p>
                        <p className="forcastTempLow">{weatherResponse.forecast.forecastday[1].day.mintemp_c} &deg;C</p>
                        <p className="forcastDate">{weatherResponse.forecast.forecastday[1].date}</p>
                    </div>
                    <div className="forecastDayOne forecastItem">
                        <p className="forcastTempHigh">{weatherResponse.forecast.forecastday[2].day.maxtemp_c} &deg;C</p>
                        <p className="forcastTempLow">{weatherResponse.forecast.forecastday[2].day.mintemp_c} &deg;C</p>
                        <p className="forcastDate">{weatherResponse.forecast.forecastday[2].date}</p>
                    </div>
                </div>
                <p></p>
            </motion.div>
        )
    }
    
}