import './Nav.css'
import { Link } from 'react-router-dom'
import avatar from './assets/ava.png'
import sun from './assets/sun.svg'
import moon from './assets/moon.svg'

export default function Nav( { logLayout, darkMode, modal, toggleModal } ) {

    return (
        <div className="nav">
            <Link to="/"><h2 className="bold">Koen Vleghels</h2></Link>
            <div className="nav-right">
                <button className='modeButton' onClick={logLayout}>{darkMode ? <img src={sun} alt='sun' /> : <img src={moon} alt='moon' />}</button>
                {/* <button className='modeButton weight' >NL</button> */}
                <button className='modeButton' onClick={toggleModal}><img src={avatar} className="profile-picture" alt="profile" /></button>
            </div>
        </div>
    )
}