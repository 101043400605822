import '../Post.css'
import Skills from '../Skills/Skills'
import head from '../assets/wordpress-header.png'

// npm packages

import { motion } from 'framer-motion'

export default function PostTen() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }

    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title">Wordpress full site editing</h2>
                        <p className="post-intro">WordPress thema's ontwikkelen met Full Site Editing (FSE). FSE opent nieuwe deuren en biedt een geheel nieuwe manier om WordPress-thema's te maken, waardoor het proces veel intuïtiever wordt. Het gebruik van blokken, zoals de Gutenberg-editor, stelt me in staat om diverse secties en componenten te maken en deze eenvoudig te hergebruiken op verschillende pagina's.</p>
                        <h3>Bekende concepten / vaardigheden</h3>
                        <div className="post-list-container">
                            <ul className="post-list">
                                <li>Thema's ontwikkelen die de full site editor ondersteunen</li>
                                <li>Custom templates ontwikkelen die volledig door de eindgebruiker kunnen worden bijgewerd</li>
                                <li>Custom template parts ontwikkelen</li>
                                <li>Custom Gutenberg blocks ontwikkelen</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <p>Sinds de introductie van WordPress update 5.9 met Full Site Editing (FSE) heeft het platform een aanzienlijke verandering ondergaan. Deze nieuwe functie heeft de manier waarop we thema's bouwen, inhoud beheren en websites maken, drastisch veranderd.</p>
                    <p>Het vergde enige tijd om volledig vertrouwd te raken met de nieuwe mogelijkheden en concepten die (FSE) bied, maar de leercurve was het absoluut waard. Ik ben ervan overtuigd dat FSE de toekomst is van het ontwikkelen van WordPress-thema's.</p>
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}