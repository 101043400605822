import './Weather.css'

// components

import Forecast from './Forecast/Forecast'

// npm packages

import { useState } from 'react'

export default function Weather() {

    let [weatherLocation, changeWeatherLocation] = useState("")
    let [weatherResponse, updateWeatherResponse] = useState("")

    function handleSubmit(e) {
        e.preventDefault()
        fetch(`https://weatherapi-com.p.rapidapi.com/forecast.json?q=${weatherLocation}&days=3`, {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': `${process.env.REACT_APP_KEY}`,
            'X-RapidAPI-Host': 'weatherapi-com.p.rapidapi.com'
        }

       })
       .then(response => response.json())
       .then(data => updateWeatherResponse(data))
    }

    function updateLocation(e) {
        changeWeatherLocation(e.target.value)
    }

    return(
        <div className='weather-container'>
            <h3>WeatherAPI</h3>
            <p>Met de fetch methode roep ik de WeatherAPI forecast endpoint aan en maak ik gebruik van de input van de gebruiker om de data van de weervoorspelling te krijgen. Als de gebruiker een herkende plaatsnaam invoerd, wordt deze aan data aan de voorkant getoond. Word de plaatsnaam niet herkend, of word het endpoint om andere redenen niet bereikt, dan word er een foutmelding getoond.</p>
            <p className="weather-info">Voer hieronder een plaatsnaam in om de en klik op 'Test' om de API te testen</p>
            <form onSubmit={handleSubmit} className='weather-form'>
                <input type="text" onChange={updateLocation} className='weather-input' />
                <button type="submit" className='weather-button'>Test</button>
            </form>
            <Forecast weatherResponse={weatherResponse} />
        </div>
    )
}