import '../Post.css'
import Skills from '../Skills/Skills'
import { motion } from 'framer-motion'
import head from '../assets/javascript-header.png'

export default function PostFour() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }

    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title">Javascript</h2>
                        <p className="post-intro">Javascript is een van de populairste programmeertalen voor webontwikkeling. Als frontend developer was dit de eerste scripting taal die ik mezelf eigen heb gemaakt. JavaScript is een krachtige taal waarmee ik interactieve webpagina's en webapplicaties kan maken.</p>
                        <h3>Bekende concepten / vaardigheden</h3>
                        <div className="post-list-container">
                            <ul className="post-list">
                                <li>Begrip van variabelen, datatypen, arrays, objecten, functies en methoden</li>
                                <li>Manipuleren van en omgaan met de DOM</li>
                                <li>Begrip van moderne JavaScript-concepten zoals Promises, Async/Await en ES6+ syntax</li>
                                <li>Kennis van JavaScript-framework React</li>
                                <li>Oog voor performance en optimalisatie</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <p>Een van de eerste dingen die ik leerde over JavaScript was hoe ik het in mijn HTML-bestanden kon opnemen en er mee kon werken. Ik gebruikte de script-tag om JavaScript-code in mijn pagina's te schrijven en deze in de browser uit te voeren. Kort daarna leerde ik over variabelen, functies, conditionele logica en loops, allemaal essentiële concepten om te begrijpen wanneer je met JavaScript werkt.</p>
                    <p>JavaScript wordt op de client-side, wat betekent dat het afhankelijk is van de browser waarin het wordt uitgevoerd. Dit betekent dat ik moet testen op verschillende browsers om er zeker van te zijn dat mijn code correct werkt in alle situaties. Daarom is het belangrijk om op de hoogte te blijven van de nieuwste ontwikkelingen op het gebied van JavaScript en de beste praktijken te volgen om te zorgen dat de code die ik schrijf betrouwbaar en compatibel is.</p>
                    {/* <p className="post-code">Code here</p> */}
                    <p>Een ander interessant concept (vooral als front-end developer) is het werken met de Document Object Model (DOM), die de structuur van HTML-documenten in de browser beschrijft. Het gebruik van de DOM stelt me in staat om de inhoud van een pagina te manipuleren en interactieve functionaliteit toe te voegen, zoals het veranderen van de inhoud van een pagina op basis van gebruikersinteractie. Frameworks als React maken dit process stukken eenvoudiger door een ingebouwde virtuele DOM, die mits correct gebruikt, eenvoudiger te manipuleren is.</p>
                    <p>Een belangrijke ontwikkeling in het werken met JavaScript is de opkomst van JavaScript-frameworks zoals React, Angular en Vue. Deze frameworks bieden krachtige tools voor het bouwen van webapplicaties en het werken met complexe gegevensstructuren. Ik heb er voor gekozen om React mij eigen te maken, omdat het het meest populaire JavaScript framework van dit moment is.</p>
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}