import '../Post.css'
import Skills from '../Skills/Skills'
import head from '../assets/figma-header.png'
import Weather from '../Weather/Weather'

// npm packages

import { motion } from 'framer-motion'

export default function PostNine() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }

    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title">Webdesign</h2>
                        <p className="post-intro">Als ontwikellaar is ontwerpen niet mijn hoofdtaak, maar desondanks toch een vaardigheid die ik me met plezier eigen heb gemaakt. Figma is mijn go-to ontwerp software en het helpt me binnen no time een passend en op het web afgestemd ontwerp neer te zetten.</p>
                        <p>Met het maken van een ontwerp voor een website vind ik het belangrijk om te begrijpen wie de doelgroep is en wat hun behoeften zijn. Door te begrijpen wie de website zal gebruiken en wat ze willen bereiken, kan ik een ontwerp maken dat hun behoeften en wensen weerspiegelt. Ik hou rekening met factoren zoals kleur, lettertype, layout en navigatie om een website te ontwerpen die zowel aantrekkelijk als functioneel is.</p>
                        <p>Ik hou graag de nieuwste trends in de gaten om een website te creëren die zich onderscheidt van andere websites. Dit betekent dat ik op de hoogte blijf van nieuwe ontwikkelingen op het gebied van webdesign en de nieuwste tools en technieken wil ontdekken.</p>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <h3>UX</h3>
                    <p>Een ander belangrijk aspect van webdesign is de gebruikerservaring (UX). Een goede UX betekent dat de website gemakkelijk te navigeren is, snel laadt en relevante informatie bevat. Dit kan de algehele klantervaring verbeteren en de kans vergroten dat gebruikers terugkeren naar de website.</p>
                    <p>Tot slot vind ik het belangrijk om open te staan voor feedback van anderen en om continu te blijven leren en groeien als webdesigner.</p>
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}