import '../Post.css';
import Skills from '../Skills/Skills';
import { motion } from 'framer-motion';
import head from '../assets/react-header.png';

export default function PostTwo() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }


    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title">Met React werken</h2>
                        <p className="post-intro">React is een krachtig JavaScript-framework dat me in staat stelt om snel en efficiënt user interfaces te bouwen voor webapplicaties en mobiele apps. Het is momenteel mijn favoriete manier om een interactive webapp te maken. De virtuele DOM maakt het mogelijk om met relatief weinig code, estische prachtige webpagina's te maken. Ook kan ik door React te gebruiken gestructureerde en schaalbare code schrijven, wat het gemakkelijker maakt om mijn applicaties te onderhouden en uit te breiden.</p>
                        <h3>Bekende concepten / vaardigheden</h3>
                        <div className="post-list-container">
                            <ul className="post-list">
                                <li>Begrip van JSX</li>
                                <li>Schrijven van nette te begrijpen componenten</li>
                                <li>State gebruiken om een interactive app te maken</li>
                                <li>Kennis van React hooks</li>
                                <li>Kennis van diverse React npm packages</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <p>Wat ik het meeste waardeer aan het werken met React is hoe eenvoudig het is om een interactive app te maken. De virtuele DOM maakt visuele aanpassingen op basis van interactie met de app eenvoudiger, sneller overzichtelijker dan code geschreven in 'vanilla' Javascript.</p>
                    <h3>React Hooks</h3>
                    <p>De useState-hook is een van de meest gebruikte hooks die ik vaak toepas. Hiermee kan ik gemakkelijk state toevoegen aan functionele componenten. Dit doe ik door de useState-functie aan te roepen, die een array teruggeeft met de huidige state en een functie om de state te updaten. Het gebruik van deze functie maakt het mogelijk om de state van het component te veranderen en het component opnieuw te renderen.</p>
                    <p>Ook gebruik ik in virtueel ieder project de React's useEffect hook. Ik vind het een krachtige en flexibele tool die me in staat stelt om de effecten van wijzigingen in de state van een component te beheren en de weergave van mijn UI dynamisch bij te werken.</p>
                    <p>Een van de belangrijkste voordelen van useEffect is dat het je de mogelijkheid biedt om code uit te voeren wanneer er een wijziging in de state van je component optreedt. Dit kan variëren van het bijwerken van een DOM-element tot het aanroepen van een externe API.</p>
                    <h3>Npm packages</h3>
                    <p>Het fijne aan het werken met React is dat er een grote en levendige community aan ontwikkelaars voor bestaat. Hierdoor zijn er oplossingen in de vorm van npm packages voor bijna alle voorkomende werkzaamheden en problemen. Zo gebruik ik voor mijn frontend werk graag de npm package 'framer-motion'. Deze javascript library geeft me de mogelijkheid om eenvoudig animaties aan een webapp toe te voegen. Ook react-router is een package die ik graag gebruiken voor instellen van Routes en endpoints, zonder dat ik een backend voor mijn app nodig heb.</p>
                    {/* <p className="post-code">Code here</p> */}
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}