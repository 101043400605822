import './Skills.css'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import wordpress from './assets/wordpress-light.png'
import html from './assets/html-light.png'
import react from './assets/react-light.png'
import javascript from './assets/javascript-light.png'
import git from './assets/git-light.png'
import home from './assets/home-light.png'
import sass from './assets/sass-light.png'
import figma from './assets/figma-light.png'

export default function Skills () {
    const MotionLink = motion(Link)

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.2,
            delay: 0.4
        } }
    }

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 }
    }

    return (
        <div className="skills-container">
            <div className="skills-wrapper">
                <motion.h2 initial={{x: 22, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 0.2, delay: 0.3}}>Andere vaardigheden</motion.h2>
                <motion.div variants={container} initial="hidden" animate="show" className="skills posts">
                    <MotionLink variants={item} to="/posts/wordpress-full-site-editing"><div className="post-one skills-post"><img src={wordpress} alt="" /><p className="white">Wordpress full site editing</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/wordpress-themas-en-plugins"><div className="post-one skills-post"><img src={wordpress} alt="" /><p className="white">Wordpress thema's</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/met-react-werken"><div className="post-two skills-post"><img src={react} alt="" /><p className="white">React</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/html-en-css"><div className="post-three skills-post"><img src={html} alt="" /><p className="white">HTML 5 <br />& CSS 3</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/javascript"><div className="post-four skills-post"><img src={javascript} alt="" /><p className="white">JavaScript</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/wordpress-lokaal"><div className="post-five skills-post"><img src={wordpress} alt="" /><p className="white">Wordpress lokaal</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/git-versiebeheer"><div className="post-six skills-post"><img src={git} alt="" /><p className="white">Git versiebeheer</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/apis-gebruiken"><div className="post-seven skills-post"><img src={javascript} alt="" /><p className="white">API's</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/sass"><div className="post-eight skills-post"><img src={sass} alt="" /><p className="white">Sass</p></div></MotionLink>
                    <MotionLink variants={item} to="/posts/webdesign"><div className="post-nine skills-post"><img src={figma} alt="" /><p className="white">Webdesign</p></div></MotionLink>
                </motion.div>
            </div>
            <div className="skills-home-wrapper posts">
                <div className="skills-home">
                <motion.h2 initial={{x: 22, opacity: 0}} animate={{x: 0, opacity: 1}} transition={{duration: 0.2, delay: 0.5}}>Home</motion.h2>
                    <motion.div variants={container} initial="hidden" animate="show" className="skills posts">
                        <MotionLink variants={item} to="/"><div className="post-one skills-post"><img src={home} alt="" /><p className="white">Home</p></div></MotionLink>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}