import { useParams } from 'react-router-dom'
import Post from './Articles/Post'
import PostTwo from './Articles/PostTwo'
import PostThree from './Articles/PostThree'
import PostFour from './Articles/PostFour'
import PostFive from './Articles/PostFive'
import PostSix from './Articles/PostSix'
import PostSeven from './Articles/PostSeven'
import PostEight from './Articles/PostEight'
import PostNine from './Articles/PostNine'
import PostTen from './Articles/PostTen'


export default function Posts() {
    const params = useParams()

        switch(params.id) {
            case "wordpress-themas-en-plugins":
                return (
                    <Post />
                )
            case "met-react-werken":
                return(
                    <PostTwo />
                )
            case "html-en-css":
                return (
                    <PostThree />
                )
            case "javascript":
                return (
                    <PostFour />
                )
            case "wordpress-lokaal":
                return(
                    <PostFive />
                )
            case "git-versiebeheer":
                return (
                    <PostSix />
                )
            case "apis-gebruiken":
                return (
                    <PostSeven />
                )
            case "sass":
                return (
                    <PostEight />
                )
            case "webdesign":
                return (
                    <PostNine />
                )
            case "wordpress-full-site-editing":
                    return (
                        <PostTen />
                    )
            default:
            return(
                    <p>We kunnen dit artikel niet vinden, ga terug naar Home.</p>
                )
        }
}