import './Footer.css'
import { motion } from 'framer-motion'

export default function Footer() {

    return(
        <>
            <motion.div animate={{opacity: 1, y: 0}} initial={{opacity: 0, y: 40}} transition={{delay: 0.8}} className="footer">
                <div className="footer-text border">
                    <h2>Over</h2>
                    <p>Mijn naam is Koen ik ben een front-end webontwikkelaar. Ik heb passie voor het creëren van prachtige en gebruiksvriendelijke websites, en ik ben blij dat ik een aantal jaar geleden van deze passie mijn carrière heb kunnen maken.</p>
                    <p>Als front-end webontwikkelaar heb ik me gespecialiseerd in het bouwen van websites die zowel esthetisch aantrekkelijk als functioneel zijn. Ik ben bedreven in HTML, CSS, Javascript en Javascript framework React. Ook ben ik bedreven in het ontwikkelen van Wordpress thema's en plugin's en heb ik de nodige kennis van php en mysql.</p>
                    <p>Deze website is bedoeld om geïnteresseerde een beeld te geven van mijn vaardigheden als ontwikkelaar. Ik zal de website up-to-date houden zodat het een ware reflectie is van waar ik sta in mijn pad als onwikkelaar. Bedankt voor je interesse en veel lees plezier.</p>
                </div>
            </motion.div>
            <motion.div animate={{opacity: 1, y: 0}} initial={{opacity: 0, y: 40}} transition={{delay: 0.8}} className="footer">
                <div className="footer-text shrink">
                    <h2>Dev tools</h2>
                    <ul className="footer-ul">
                        <li className="external-link"><a href="https://css-tricks.com/" target="blank">css-tricks</a></li>
                        <li className="external-link"><a href="https://blog.webdevsimplified.com/" target="blank">webdevsimplified</a></li>
                        <li className="external-link"><a href="https://www.w3schools.com/" target="blank">w3schools</a></li>
                        <li className="external-link"><a href="https://stackoverflow.com/" target="blank">stackoverflow</a></li>
                    </ul>
                </div>
                <div className="footer-text shrink">
                    <h2>Design tools</h2>
                    <ul className="footer-ul">
                        <li className="external-link"><a href="https://figma.com" target="blank">figma</a></li>
                        <li className="external-link"><a href="https://affinity.serif.com/" target="blank">affinity-photo</a></li>
                        <li className="external-link"><a href="https://www.pexels.com/" target="blank">pexels</a></li>
                    </ul>
                </div>
                <div className="footer-text">
                    <h2>Contact</h2>
                    <p>Als je vragen hebt over mijn vaardigheden als ontwikkelaar, of als je geïnteresseerd bent in het inhuren van mij voor je webontwikkelingsbehoeften, dan kun je contact met mij opnemen via e-mail.</p>
                </div>
            </motion.div>
        </>
    )
}