import '../Post.css'
import Skills from '../Skills/Skills'
import { motion } from 'framer-motion'
import head from '../assets/wordpress-header.png'

export default function PostFive() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }


    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title">Wordpress lokale workflow</h2>
                        <p className="post-intro">Als ontwikkelaar werk ik graag met WordPress en ik heb ontdekt dat het opzetten van een lokale ontwikkelomgeving in WSL en de terminal erg handig kan zijn. Het biedt me de flexibiliteit en controle die ik nodig heb om mijn WordPress-projecten te beheren en te ontwikkelen.</p>
                        {/* <p className="post-code">Code here</p> */}
                        <h3>Bekende concepten / vaardigheden</h3>
                        <div className="post-list-container">
                            <ul className="post-list">
                                <li>Installeren van WSL 2, Ubuntu, terminal en de benodigde services als verschillende php versies, mysql</li>
                                <li>Basis kennis van Linux en Linux commands</li>
                                <li>Omgaan met de terminal</li>
                                <li>Behendig met WP CLI voor het efficiënt beheren van posts, categoriën, gebruikers.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    {/* <p className="post-code">Code here</p> */}
                    
                    <h3>WSL 2 en Ubuntu</h3>
                        <p>Het opzetten van een lokale ontwikkelomgeving in WSL en de terminal kan in eerste instantie een beetje intimiderend lijken, maar het is eigenlijk vrij eenvoudig. Ik maak gebruik van een combinatie van WSL 2, Ubuntu en de terminal om mijn ontwikkelomgeving op te zetten en te beheren.</p>
                    <p>Een van de grootste voordelen van het werken met WordPress in WSL en de terminal is dat ik de volledige controle heb over mijn ontwikkelomgeving. Ik kan bijvoorbeeld gemakkelijk een nieuwe WordPress-site opzetten door simpelweg een nieuw project in mijn terminal te creëren en WordPress te installeren met behulp van de WP-CLI</p>
                    <p>Bovendien kan ik via de terminal verschillende taken uitvoeren, zoals het installeren van nieuwe WordPress-plugins of thema's en het beheren van de WordPress-database. Dit bespaart me tijd en zorgt ervoor dat ik mijn taken efficiënter kan uitvoeren.</p>
                    <p>Een ander voordeel van het werken met WordPress in WSL en de terminal is dat het platform platformonafhankelijk is. Dit betekent dat ik mijn ontwikkelomgeving gemakkelijk kan overzetten naar een andere machine, ongeacht het besturingssysteem.</p>
                    {/* <p className="post-code">Code here</p> */}
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}