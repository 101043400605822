import '../Post.css'
import { motion } from 'framer-motion'
import head from '../assets/git-header.png'
import Skills from '../Skills/Skills'

export default function PostSix() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }

    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title translate">Git versiebeheer</h2>
                        <p className="post-intro">Git is een versiebeheersysteem waarmee ik de code van mijn projecten kan bijhouden en beheren. Het stelt mij in staat om wijzigingen in de code te beheren, samen te werken met andere ontwikkelaars en fouten in de code op te sporen en op te lossen.</p>
                        <h3>Bekende concepten / vaardigheden</h3>
                        <div className="post-list-container">
                            <ul className="post-list">
                                <li>Installeren git</li>
                                <li>Aanmaken repositories of branches</li>
                                <li>Bekend met basis git push / pull commands</li>
                                <li>Aanmaken en koppelen SSH sleutels</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <p>Een van de grote voordelen van Git is dat het samenwerken aan een codebase stukken eenvoudiger maakt. Door slim gebruik te maken van branches en door het bijhouden van wijzigingen in de code kan heel het team er zeker van zijn dat er geen werk verloren gaat. Ook vind ik het handig om wijzigingen en werkwijzen van andere ontwikkelaars te kunnen volgen en daarvan te leren.</p>
                    <p>Ik heb ervaring met zowel Bitbucket als GitHub om mijn code op te slaan en te beheren. Beide platforms zijn populair in de ontwikkelingsgemeenschap en bieden uitgebreide functies voor het hosten van repositories en het samenwerken met anderen.</p>
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}