import '../Post.css'
import Skills from '../Skills/Skills'
import head from '../assets/sass-header.png'
import Weather from '../Weather/Weather'

// npm packages

import { motion } from 'framer-motion'

export default function PostEight() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }

    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title">Sass</h2>
                        <p className="post-intro">Ik vind Sass een waardevolle tool die mij helpt bij het creëren van efficiënte en goed georganiseerde CSS-stijlbladen voor mijn webprojecten.</p>
                        <p>Een aspect van Sass waar ik veel waarde aan hecht, is de mogelijkheid om modulair te werken met @import en @extend, waarmee ik de CSS van verschillende stijlbladen kan samenvoegen en duplicatie kan voorkomen. Dit maakt het gemakkelijker om te werken met grote en complexe projecten, waardoor mijn workflow soepeler verloopt.</p>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <p>Daarnaast maak ik regelmatig gebruik van Sass-nesten, waarmee ik stijlregels kan groeperen op basis van hun relatie tot elkaar, wat zorgt voor een overzichtelijke en georganiseerde code. Met Sass-functies en mixins kan ik ook complexe stijlregels creëren die gemakkelijk kunnen worden aangepast en hergebruikt.</p>
                    <p>Variabelen stellen me in staat om kleuren, lettertypen, en andere stijlelementen op te slaan als herbruikbare waarden, waardoor ik efficiënter kan werken en consistentie kan garanderen in het hele stijlblad.</p>
                    <p>Kortom, mijn vaardigheden met Sass stellen mij in staat om gestructureerde, herbruikbare en schaalbare css te maken die gemakkelijk te onderhouden zijn.</p>
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}