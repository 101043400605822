import './Home.css'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import wordpress from './assets/wordpress-light.png'
import html from './assets/html-light.png'
import react from './assets/react-light.png'
import javascript from './assets/javascript-light.png'
import git from './assets/git-light.png'
import sass from './assets/sass-light.png'
import figma from './assets/figma-light.png'

export default function Home() {

    const MotionLink = motion(Link)

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.1
        } }
    }

    const item = {
        hidden: { opacity: 0, y: 20 },
        show: { opacity: 1, y: 0 }
    }

    return ( 
        <div className="start">
            <motion.div animate={{x: 0, opacity: 1}} initial={{x: 20, opacity: 0}} className="start-intro">
                <p className="start-intro-text translate">Passie voor het maken en onderhouden van websites en webapps. Gespecialiseerd in client site of front-end development, maar maakt graag een uitstapje naar back-end en design.</p>
                <p className='translate start-text'>Ik denk mee met het ontwikkelen van een omgeving waar de klant wat aan heeft, of het gaat om een kleine informatieve website of een uitgebreide webapp, ik ben enthousiast over alles rondom het web.</p>
                <p className='translate start-text'>Bekijk een onderwerp om meer te weten te komen over mijn vaardigheden als ontwikkelaar.</p>
            </motion.div>
            <motion.div variants={container} initial="hidden" animate="show" className="start-posts">
                <MotionLink variants={item} to="/posts/wordpress-full-site-editing"><div className="post-one post"><img src={wordpress} alt="" /><p className="white translate">Wordpress<br />full site<br/>editing</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/wordpress-themas-en-plugins"><div className="post-one post"><img src={wordpress} alt="" /><p className="white translate">Wordpress<br />thema's</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/met-react-werken"><div className="post-two post"><img src={react} alt="" /><p className="white translate">React</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/html-en-css"><div className="post-three post"><img src={html} alt="" /><p className="white translate">HTML 5<br />& CSS 3</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/javascript"><div className="post-four post"><img src={javascript} alt="" /><p className="white translate">Javascript</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/wordpress-lokaal"><div className="post-five post"><img src={wordpress} alt="" /><p className="white translate">Wordpress<br /> lokaal</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/git-versiebeheer"><div className="post-six post"><img src={git} alt="" /><p className="white translate">Git<br /> versiebeheer</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/apis-gebruiken"><div className="post-seven post"><img src={javascript} alt="" /><p className="white translate">API's</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/sass"><div className="post-eight post"><img src={sass} alt="" /><p className="white translate">Sass</p></div></MotionLink>
                <MotionLink variants={item} to="/posts/webdesign"><div className="post-eight post"><img src={figma} alt="" /><p className="white translate">Webdesign</p></div></MotionLink>
            </motion.div>
        </div>
    )
}