import '../Post.css'
import { motion } from 'framer-motion'

import head from '../assets/wordpress-header.png'
import Skills from '../Skills/Skills'

export default function Post() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }

    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title translate">Wordpress thema's en plugins</h2>
                        <p className="post-intro">Als ontwikkelaar heb ik ervaring met het bouwen van WordPress thema's en plugins. Het is een boeiende uitdaging om de beperkingen van het platform te omzeilen en oplossingen te bedenken die de functionaliteit van WordPress kunnen uitbreiden en met de tijd mee te laten gaan.</p>
                        <h3>Bekende concepten / vaardigheden</h3>
                        <div className="post-list-container">
                            <ul className="post-list">
                                <li>Index, post, page, archive, header, footer, search templates maken</li>
                                <li>Woocommerce templates maken</li>
                                <li>Custom thema's en plugin's</li>
                            </ul>
                            <ul className="post-list">
                                <li>Database aanmaken, koppelen, exporteren en importeren</li>
                                <li>Behendig met WP CLI</li>
                                <li>Bekend met plugins: Woocommerce, Advanced custom fields, Diverse contact forms, Diverse caching plugins, Elementor, WP Bakery, Visual composer en meer</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <h3>Wordpress thema's</h3>
                    <p>Het bouwen van WordPress thema's stelt me in staat om creatief te zijn en mooie, op maat gemaakte ontwerpen te maken die perfect passen bij de behoeften van mijn klanten. Ik vind het ook leuk om te spelen met de vele functies en mogelijkheden die WordPress biedt, zoals aanpasbare widgets, paginatemplates en custom code blokken.</p>
                    <h3>Wordpress plugins</h3>
                    <p>Het uitbreiden van Wordpress functionaliteiten doe ik graag door op zichzelfstaande plugins te onwikkelen. Het voordeel hiervan is dat het me in staat stelt extra functionaliteiten aan een Wordpress thema toe te voegen, zonder hiervoor het thema te moeten bewerken. Als ontwikkelaar volg ik graag het programmeer principe <em>'separation of concerns'</em>. Dit principe leert dat het, waar mogelijk, handig is om programmeer 'problemen' op te delen in stukjes. Deze stukjes zijn makkelijker te begrijpen, onderhouden en hergebruiken.</p>
                    <h3>Levend systeem</h3>
                    <p>Wordpress is een levend systeem, die onderhouden moet worden. Regelmatige updates van WP core, plugins en php versie horen hier bij en ik kan dit op een veilige manier realiseren.</p>
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}