import '../Post.css'
import Skills from '../Skills/Skills'
import head from '../assets/javascript-header.png'
import Weather from '../Weather/Weather'

// npm packages

import { motion } from 'framer-motion'

export default function PostSeven() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }

    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title">API's gebruiken</h2>
                        <p className="post-intro">Ik vind het fascinerend hoe API's de kracht hebben om verschillende applicaties en systemen met elkaar te verbinden en een naadloze gebruikerservaring te bieden.</p>       
                        {/* <p className="post-code">Code here</p> */}
                        <h3>Bekende concepten / vaardigheden</h3>
                        <div className="post-list-container">
                            <ul className="post-list">
                                <li>Bekend met fetch</li>
                                <li>Bekend met aanpassen van DOM elementen op basis van API data</li>
                                <li>Bekend met veilig omgaan van API sleutels en environment variabelen</li>
                                <li>Behendig met Postman en RapidAPI voor het verkennen en begrijpen van API endpoints</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <p>Het ontwikkelen met API's biedt voordelen, zoals het verminderen van dubbel werk en het efficiënter maken van processen. Bij het werken met API's heb ik geleerd hoe belangrijk het is om goed te begrijpen hoe de API werkt en welke endpoints en methodes beschikbaar zijn. Ik maak vaak gebruik van Rapid API om api's voor mijn projecten te vinden en testen. Ook Postman gebruik ik graag om beschikbare endpoints te testen.</p>
                    <h3>Live voorbeelden</h3>
                    <p>API's komen in allerlij soorten en maten. Zo worden verbindingen met betaal systemen als iDEAL of paypal gedaan via een API. Hieronder een aantal voobeelden van data die live word opgehaald via een API.</p>
                    <Weather />
                    <p>Wat ik vooral waardeer aan het werken met API's is de mogelijkheid om data te verkrijgen en te gebruiken van verschillende bronnen. Dit stelt me in staat om complexe applicaties te bouwen die gebruik maken van verschillende gegevensbronnen en zo een meer complete gebruikerservaring bieden.</p>
                    {/* <p className="post-code">Code here</p> */}
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}