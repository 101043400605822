import '../Post.css'
import Skills from '../Skills/Skills'
import { motion } from 'framer-motion'
import head from '../assets/html-header.png'

export default function PostThree() {

    const container = {
        hidden: { opacity: 0 },
        show: { opacity: 1,
        transition: {
            staggerChildren: 0.3
        } }
    }

    const item = {
        hidden: { opacity: 0, x: 20 },
        show: { opacity: 1, x: 0 }
    }

    return(
        <>
            <motion.div variants={container} initial="hidden" animate="show" className="post-container">
                <motion.div variants={item} className="post-rows">
                    <div className="post-row">
                        <h2 className="post-title translate">HTML 5 & CSS 3</h2>
                        <p className="post-intro translate">HTML en CSS zijn de basis van elke website en zonder deze vaardigheden is het moeilijk om een website te bouwen. Ik heb een grondige kennis van zowel HTML 5 als CSS 3.</p>  
                        <p className="post-code"><code>	&lt;p&gt;Hello world!&lt;/p&gt;</code></p>
                        <h3>Bekende concepten / vaardigheden</h3>
                        <div className="post-list-container">
                            <ul className="post-list">
                                <li>Grondige kennis van HTML syntax</li>
                                <li>Kennis van HTML formulieren en form validatie</li>
                                <li>Kennis van Toegangkelijke HTML voor o.a. screenreaders</li>
                                <li>Begrip van SEO resultaten op basis van HTML indeling</li>
                                <li>Kennis van HTML5 en de semantische verbeteringen</li>
                            </ul>
                            <ul className="post-list">
                                <li>Grondige kennis van CSS syntax; selectors, properties en values</li>
                                <li>Kennis van het box model</li>
                                <li>Kennis van flex en grid layouts</li>
                                <li>Kennis van CSS variabelen</li>
                                <li>Bekend met CSS-preprocessors Sass</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-row">
                        <img className="fit" src={head} alt="" />
                    </div>
                </motion.div>
                <motion.div variants={item}>
                    <h3>HTML</h3>
                    <p>Het werken met HTML en CSS is een fascinerend en creatief proces. Door de vele beschikbare frameworks en tools bouw ik een website haast nooit meer met enkel HTML en CSS, maar dit blijft wel de basis van bijna iedere site of applicatie op het web. Ik heb dan ook grondige kennis van HTML en kan moeiteloos logische, toegangkelijke en semantische HTML schrijven en door HTML en CSS op de juiste manier te combineren, kan ik websites maken die er niet alleen goed uitzien, maar ook een geweldige gebruikerservaring bieden.</p>
                    <h3>CSS</h3>
                    <p>Een van de dingen die ik waardeer aan het werken met CSS is de eenvoudigeheid waarmee ik mijn creatieve ideeën om kan zetten in een werkende website. Ik kan bijvoorbeeld unieke lay-outs en ontwerpen creëren door gebruik te maken van flexbox, grid en CSS-animaties. Ook maak ik gebruik van logische selectors in de vorm van id's of classes, waardoor stijlen makkelijk elders in de app hergebruikt kunnen worden. Zo word het aanpassen van kleuren, fonts, layouts en al het geen dat maar zichtbaar kan zijn op het web, een eenvoudig en intuitief process.</p>
                    <p className="post-code"><code>.container &#123;display: flex&#125;</code></p>
                </motion.div>
            </motion.div>
            <Skills />
        </>
    )
}